@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  font-family: 'Lato', sans-serif;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
}

/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type="range"]::-webkit-slider-runnable-track {
  background: #FFFFFF;
  height: 1rem;
  border-radius: 1rem;
}

/******** Firefox ********/
input[type="range"]::-moz-range-track {
  background: #FFFFFF;
  height: 1rem;
  border-radius: 1rem;
}

@for $i from 1 through 100 {
  input[type="range"].value-#{$i}::-moz-range-track {
    background: linear-gradient(90deg, #aec7ca #{$i + '%'}, #FFFFFF #{$i + '%'})
  }
}

@for $i from 1 through 100 {
  input[type="range"].value-#{$i}::-webkit-slider-runnable-track {
    background: linear-gradient(90deg, #aec7ca #{$i + '%'}, #FFFFFF #{$i + '%'})
  }
}

/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -.5rem; /* Centers thumb on the track */
  background-color: #3c6a9f;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
}

/***** Firefox *****/
input[type="range"]::-moz-range-thumb {
  border: none; /*Removes extra border that FF applies*/
  background-color: #3c6a9f;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
}

/* Removes default focus */
input[type="range"]:focus {
  outline: none;
}

/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type="range"]:focus::-webkit-slider-thumb {
  border: 1px solid #294463;
  outline: 3px solid #294463;
  outline-offset: 0.125rem;
}

/******** Firefox ********/
input[type="range"]:focus::-moz-range-thumb {
  border: 1px solid #294463;
  outline: 3px solid #294463;
  outline-offset: 0.125rem;
}


main.aqua textarea, main.aqua input,  main.aqua select {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(71 169 202 / var(--tw-bg-opacity)) !important;
}

main.yellow textarea, main.yellow input,  main.yellow select {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(251 188 96 / var(--tw-bg-opacity)) !important;
}

main.red textarea, main.red input,  main.red select {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(223 98 96 / var(--tw-bg-opacity)) !important;
}

main.green textarea, main.green input,  main.green select {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(62 201 162 / var(--tw-bg-opacity)) !important;
}

main.grey textarea, main.grey input,  main.grey select {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(174 199 202 / var(--tw-bg-opacity)) !important;
}

.underline {
  text-decoration: underline!important;
  text-underline-offset: 2px;
}
